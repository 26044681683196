
import { defineComponent } from 'vue';
import { SImageLazy } from '@strana-artw/ui-kit';
import HeaderPhoneNumber from '@/components/layout/HeaderPhoneNumber.vue';
import CallbackModal from '@/components/common/modals/CallbackModal.vue';

export default defineComponent({
  name: 'ManagerFavoritesDetail',

  components: {
    CallbackModal,
    HeaderPhoneNumber,
    SImageLazy
  },

  middleware: [
    'tokenAuth'
  ],

  data () {
    return {
      isCallbackModalOpen: false
    };
  },

  head () {
    return {
      title: '«Страна Девелопмент» - Личный кабинет клиента',
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: '«Страна Девелопмент» - Личный кабинет клиента'
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: 'Личный кабинет клиента «Страна Девелопмент». Квартиры и коммерческие помещения в новостройках от застройщика.'
        },
        {
          hid: 'description',
          name: 'description',
          content: 'Личный кабинет клиента «Страна Девелопмент». Квартиры и коммерческие помещения в новостройках от застройщика.'
        }
      ]
    };
  },

  computed: {
    logoImage () {
      return '/images/developer-logo.svg';
    },

    phone () {
      // FIXME: Брать телефон из бека, когда бек будет готов
      return '+78002220202';
    }
  },

  methods: {
    CallbackModalOpenHandler () {
      this.isCallbackModalOpen = true;
    }
  }
});
