import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useContext } from '@nuxtjs/composition-api';
import projectInfraMapCategories from '@/queries/projectInfraMapCategories.gql';
import { MapInfraCategory } from '@/types/ProjectInfraMap';

interface MapCategory {
  color: string;
  icon: string;
  id: string;
  name: string;
  infraSet: {
    edges: Array<{
      node: MapInfraCategory;
    }>;
  };
}

export const useManagerFavoritesStore = defineStore('managerFavorites', () => {
  const nuxtContext = useContext();

  const changedFlat = ref<number>();

  function changeFlat (flat: number): void {
    changedFlat.value = flat;
  }

  async function getCategories (projectId: string): Promise<Array<MapCategory>> {
    let categories;

    try {
      const {
        body: query
      } = projectInfraMapCategories.loc.source;

      const {
        data: {
          data
        }
      } = await nuxtContext.$portal.post('', {
        query,
        variables: {
          project: projectId
        }
      });

      categories = data?.allInfraCategories?.edges?.length
        ? data.allInfraCategories.edges.map((item: { node: MapInfraCategory }) => {
          const category = {
            ...item?.node,
            icon: item?.node.iconName
          };
          delete category.iconName;

          return category;
        })
        : [];
    } catch (error) {
      console.log('🚀 ~ file: managerFavorites.ts ~ getCategories ~ error', error);
    }

    return categories;
  }

  return {
    changedFlat,
    changeFlat,
    getCategories
  };
});
