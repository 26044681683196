import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _44b23a18 = () => interopDefault(import('../src/pages/booking.vue' /* webpackChunkName: "pages/booking" */))
const _57e5da9e = () => interopDefault(import('../src/pages/booking/confirm-booking.vue' /* webpackChunkName: "pages/booking/confirm-booking" */))
const _57fb7db3 = () => interopDefault(import('../src/pages/booking/create-booking.vue' /* webpackChunkName: "pages/booking/create-booking" */))
const _e70efea6 = () => interopDefault(import('../src/pages/booking/error.vue' /* webpackChunkName: "pages/booking/error" */))
const _14990484 = () => interopDefault(import('../src/pages/booking/offer-agreement.vue' /* webpackChunkName: "pages/booking/offer-agreement" */))
const _78b3e26b = () => interopDefault(import('../src/pages/booking/payment.vue' /* webpackChunkName: "pages/booking/payment" */))
const _3d829244 = () => interopDefault(import('../src/pages/booking/payment-status.vue' /* webpackChunkName: "pages/booking/payment-status" */))
const _e5671fa6 = () => interopDefault(import('../src/pages/booking/period-select.vue' /* webpackChunkName: "pages/booking/period-select" */))
const _5095f588 = () => interopDefault(import('../src/pages/booking/personal-data.vue' /* webpackChunkName: "pages/booking/personal-data" */))
const _bcd785ce = () => interopDefault(import('../src/components/layout/TheAsideNavigation' /* webpackChunkName: "@/components/layout/TheAsideNavigation" */))
const _49be077f = () => interopDefault(import('../src/components/layout/MobileMenu' /* webpackChunkName: "components/layout/MobileMenu" */))
const _f1efca22 = () => interopDefault(import('../src/pages/dashboard.vue' /* webpackChunkName: "" */))
const _2dc3e9fc = () => interopDefault(import('../src/pages/external-link-redirect.vue' /* webpackChunkName: "pages/external-link-redirect" */))
const _2b0583dd = () => interopDefault(import('../src/pages/fast-booking.vue' /* webpackChunkName: "pages/fast-booking" */))
const _30c86a47 = () => interopDefault(import('../src/pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _428d1906 = () => interopDefault(import('../src/pages/login-as-client.vue' /* webpackChunkName: "pages/login-as-client" */))
const _5e5f3dc2 = () => interopDefault(import('../src/pages/manager-favorites-detail/index.vue' /* webpackChunkName: "pages/manager-favorites-detail/index" */))
const _8886b454 = () => interopDefault(import('../src/pages/online-purchase.vue' /* webpackChunkName: "pages/online-purchase" */))
const _3308b3a4 = () => interopDefault(import('../src/pages/online-purchase/cash-ddu.vue' /* webpackChunkName: "pages/online-purchase/cash-ddu" */))
const _36e8224a = () => interopDefault(import('../src/pages/online-purchase/contract.vue' /* webpackChunkName: "pages/online-purchase/contract" */))
const _dd8171b4 = () => interopDefault(import('../src/pages/online-purchase/is-mortgage.vue' /* webpackChunkName: "pages/online-purchase/is-mortgage" */))
const _7fa7e81d = () => interopDefault(import('../src/pages/online-purchase/mortgage-calculator/index.vue' /* webpackChunkName: "pages/online-purchase/mortgage-calculator/index" */))
const _96fb18e6 = () => interopDefault(import('../src/pages/online-purchase/mortgage-ddu.vue' /* webpackChunkName: "pages/online-purchase/mortgage-ddu" */))
const _45d66091 = () => interopDefault(import('../src/pages/online-purchase/payment-method.vue' /* webpackChunkName: "pages/online-purchase/payment-method" */))
const _2c2f95b5 = () => interopDefault(import('../src/pages/online-purchase/upload-documents.vue' /* webpackChunkName: "pages/online-purchase/upload-documents" */))
const _4d1122ab = () => interopDefault(import('../src/pages/online-purchase/mortgage-calculator/new.vue' /* webpackChunkName: "pages/online-purchase/mortgage-calculator/new" */))
const _2a521bea = () => interopDefault(import('../src/pages/reservation-detail/index.vue' /* webpackChunkName: "pages/reservation-detail/index" */))
const _7da47e31 = () => interopDefault(import('../src/pages/token-auth.vue' /* webpackChunkName: "pages/token-auth" */))
const _8250af5c = () => interopDefault(import('../src/pages/print/booking.vue' /* webpackChunkName: "pages/print/booking" */))
const _27eb63ad = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "" */))
const _2b5d86cb = () => interopDefault(import('../src/pages/index/account.vue' /* webpackChunkName: "pages/index/account" */))
const _c73bef54 = () => interopDefault(import('../src/pages/index/documents.vue' /* webpackChunkName: "pages/index/documents" */))
const _09993875 = () => interopDefault(import('../src/pages/index/favorites.vue' /* webpackChunkName: "pages/index/favorites" */))
const _50d4d338 = () => interopDefault(import('../src/pages/index/favorites/index.vue' /* webpackChunkName: "pages/index/favorites/index" */))
const _cf26e0de = () => interopDefault(import('../src/pages/index/favorites/latest.vue' /* webpackChunkName: "pages/index/favorites/latest" */))
const _0cb855b2 = () => interopDefault(import('../src/pages/index/history.vue' /* webpackChunkName: "pages/index/history" */))
const _a5aa3f56 = () => interopDefault(import('../src/pages/index/manager-favorites.vue' /* webpackChunkName: "pages/index/manager-favorites" */))
const _847dd9d0 = () => interopDefault(import('../src/pages/index/manager-favorites/index.vue' /* webpackChunkName: "pages/index/manager-favorites/index" */))
const _01fce5ab = () => interopDefault(import('../src/pages/index/manager-favorites/apartments.vue' /* webpackChunkName: "pages/index/manager-favorites/apartments" */))
const _637f8f76 = () => interopDefault(import('../src/pages/index/manager-favorites/id.vue' /* webpackChunkName: "pages/index/manager-favorites/id" */))
const _b96fe72c = () => interopDefault(import('../src/pages/index/meetings.vue' /* webpackChunkName: "pages/index/meetings" */))
const _5bb9216e = () => interopDefault(import('../src/pages/index/mortgage.vue' /* webpackChunkName: "pages/index/mortgage" */))
const _69565d71 = () => interopDefault(import('../src/pages/index/mortgage/index.vue' /* webpackChunkName: "pages/index/mortgage/index" */))
const _179cc290 = () => interopDefault(import('../src/pages/index/mortgage/requests/index.vue' /* webpackChunkName: "pages/index/mortgage/requests/index" */))
const _25e866fa = () => interopDefault(import('../src/pages/index/mortgage/requests/booking-select.vue' /* webpackChunkName: "pages/index/mortgage/requests/booking-select" */))
const _5a3dc406 = () => interopDefault(import('../src/pages/index/mortgage/requests/new.vue' /* webpackChunkName: "pages/index/mortgage/requests/new" */))
const _85048d14 = () => interopDefault(import('../src/pages/index/mortgage/requests/upload-documents.vue' /* webpackChunkName: "pages/index/mortgage/requests/upload-documents" */))
const _3f71093d = () => interopDefault(import('../src/pages/index/notification.vue' /* webpackChunkName: "pages/index/notification" */))
const _1897e2b7 = () => interopDefault(import('../src/pages/index/privileges/index.vue' /* webpackChunkName: "pages/index/privileges/index" */))
const _03c3487e = () => interopDefault(import('../src/pages/index/privileges/index/catalog.vue' /* webpackChunkName: "pages/index/privileges/index/catalog" */))
const _662d403c = () => interopDefault(import('../src/pages/index/reservations/index.vue' /* webpackChunkName: "pages/index/reservations/index" */))
const _47b5eeb3 = () => interopDefault(import('../src/pages/index/services/index.vue' /* webpackChunkName: "pages/index/services/index" */))
const _57b43e94 = () => interopDefault(import('../src/pages/index/subscriptions.vue' /* webpackChunkName: "pages/index/subscriptions" */))
const _d88bc304 = () => interopDefault(import('../src/pages/index/reservations/history.vue' /* webpackChunkName: "pages/index/reservations/history" */))
const _84d884be = () => interopDefault(import('../src/pages/index/reservations/id.vue' /* webpackChunkName: "pages/index/reservations/id" */))
const _514601db = () => interopDefault(import('../src/pages/index/services/_id.vue' /* webpackChunkName: "pages/index/services/_id" */))
const _7571fe99 = () => interopDefault(import('../src/pages/flat/_id.vue' /* webpackChunkName: "pages/flat/_id" */))
const _af98d244 = () => interopDefault(import('../src/pages/pantry/_id.vue' /* webpackChunkName: "pages/pantry/_id" */))
const _06fc72fe = () => interopDefault(import('../src/pages/parking/_id.vue' /* webpackChunkName: "pages/parking/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: '--active-link',
  linkExactActiveClass: '--exact-link',
  scrollBehavior,

  routes: [{
    path: "/booking",
    component: _44b23a18,
    name: "booking",
    children: [{
      path: "confirm-booking",
      component: _57e5da9e,
      name: "booking-confirm-booking"
    }, {
      path: "create-booking",
      component: _57fb7db3,
      name: "booking-create-booking"
    }, {
      path: "error",
      component: _e70efea6,
      name: "booking-error"
    }, {
      path: "offer-agreement",
      component: _14990484,
      name: "booking-offer-agreement"
    }, {
      path: "payment",
      component: _78b3e26b,
      name: "booking-payment"
    }, {
      path: "payment-status",
      component: _3d829244,
      name: "booking-payment-status"
    }, {
      path: "period-select",
      component: _e5671fa6,
      name: "booking-period-select"
    }, {
      path: "personal-data",
      component: _5095f588,
      name: "booking-personal-data"
    }]
  }, {
    path: "/dashboard",
    components: {
      default: _f1efca22,
      aside: _bcd785ce,
      mobileMenu: _49be077f
    },
    name: "dashboard"
  }, {
    path: "/external-link-redirect",
    component: _2dc3e9fc,
    name: "external-link-redirect"
  }, {
    path: "/fast-booking",
    component: _2b0583dd,
    name: "fast-booking"
  }, {
    path: "/login",
    component: _30c86a47,
    name: "login"
  }, {
    path: "/login-as-client",
    component: _428d1906,
    name: "login-as-client"
  }, {
    path: "/manager-favorites/:id(\\d+)",
    component: _5e5f3dc2,
    name: "manager-favorites-id"
  }, {
    path: "/online-purchase",
    component: _8886b454,
    name: "online-purchase",
    children: [{
      path: "cash-ddu",
      component: _3308b3a4,
      name: "online-purchase-cash-ddu"
    }, {
      path: "contract",
      component: _36e8224a,
      name: "online-purchase-contract"
    }, {
      path: "is-mortgage",
      component: _dd8171b4,
      name: "online-purchase-is-mortgage"
    }, {
      path: "mortgage-calculator",
      component: _7fa7e81d,
      name: "online-purchase-mortgage-calculator"
    }, {
      path: "mortgage-ddu",
      component: _96fb18e6,
      name: "online-purchase-mortgage-ddu"
    }, {
      path: "payment-method",
      component: _45d66091,
      name: "online-purchase-payment-method"
    }, {
      path: "upload-documents",
      component: _2c2f95b5,
      name: "online-purchase-upload-documents"
    }, {
      path: "mortgage-calculator/new",
      component: _4d1122ab,
      name: "online-purchase-mortgage-calculator-new"
    }]
  }, {
    path: "/reservations/:id(\\d+)",
    component: _2a521bea,
    name: "reservation-id"
  }, {
    path: "/token-auth",
    component: _7da47e31,
    name: "token-auth"
  }, {
    path: "/print/booking",
    component: _8250af5c,
    name: "print-booking"
  }, {
    path: "/",
    components: {
      default: _27eb63ad,
      aside: _bcd785ce,
      mobileMenu: _49be077f
    },
    redirect: "/dashboard",
    name: "index",
    children: [{
      path: "account",
      component: _2b5d86cb,
      name: "index-account"
    }, {
      path: "documents",
      component: _c73bef54,
      name: "index-documents"
    }, {
      path: "favorites",
      component: _09993875,
      children: [{
        path: "",
        component: _50d4d338,
        name: "favorites"
      }, {
        path: "latest",
        component: _cf26e0de,
        name: "index-favorites-latest"
      }]
    }, {
      path: "history",
      component: _0cb855b2,
      name: "index-history"
    }, {
      path: "manager-favorites",
      component: _a5aa3f56,
      children: [{
        path: "",
        component: _847dd9d0,
        name: "index-manager-favorites"
      }, {
        path: "apartments",
        component: _01fce5ab,
        name: "index-manager-favorites-apartments"
      }, {
        path: "id",
        component: _637f8f76,
        name: "index-manager-favorites-id"
      }]
    }, {
      path: "meetings",
      component: _b96fe72c,
      name: "index-meetings"
    }, {
      path: "mortgage",
      component: _5bb9216e,
      children: [{
        path: "",
        component: _69565d71,
        name: "index-mortgage"
      }, {
        path: "requests",
        component: _179cc290,
        name: "index-mortgage-requests"
      }, {
        path: "requests/booking-select",
        component: _25e866fa,
        name: "index-mortgage-requests-booking-select"
      }, {
        path: "requests/new",
        component: _5a3dc406,
        name: "index-mortgage-requests-new"
      }, {
        path: "requests/upload-documents",
        component: _85048d14,
        name: "index-mortgage-requests-upload-documents"
      }]
    }, {
      path: "notification",
      component: _3f71093d,
      name: "notification"
    }, {
      path: "privileges",
      component: _1897e2b7,
      name: "index-privileges",
      children: [{
        path: "catalog",
        component: _03c3487e,
        name: "index-privileges-index-catalog"
      }]
    }, {
      path: "reservations",
      component: _662d403c,
      name: "index-reservations"
    }, {
      path: "services",
      component: _47b5eeb3,
      name: "index-services"
    }, {
      path: "subscriptions",
      component: _57b43e94,
      name: "index-subscriptions"
    }, {
      path: "reservations/history",
      component: _d88bc304,
      name: "index-reservations-history"
    }, {
      path: "reservations/id",
      component: _84d884be,
      name: "index-reservations-id"
    }, {
      path: "services/:id",
      component: _514601db,
      name: "index-services-id"
    }]
  }, {
    path: "/flat/:id?",
    component: _7571fe99,
    name: "flat-id"
  }, {
    path: "/pantry/:id?",
    component: _af98d244,
    name: "pantry-id"
  }, {
    path: "/parking/:id?",
    component: _06fc72fe,
    name: "parking-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
