
// Components
import { SButton, SIcon, SLoader } from '@strana-artw/ui-kit';

let uid = 0;
const defaults = {};

/* eslint-disable no-unused-expressions,no-use-before-define,brace-style */
const positionList = [
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right',
    'top',
    'bottom',
    'left',
    'right',
    'center',
];

const badgePositions = ['top-left', 'top-right', 'bottom-left', 'bottom-right'];


const groups = {};
const positionClass = {};

import { consoleError } from '../../utils/console';


export default {
    name: 'VNotifications',

    created() {
        this.notifs = {};

        positionList.forEach(pos => {
            this.notifs[pos] = [];

            const vert =
                ['left', 'center', 'right'].includes(pos) === true
                    ? 'center'
                    : pos.indexOf('top') > -1
                        ? 'top'
                        : 'bottom';
            const align =
                pos.indexOf('left') > -1
                    ? 'start'
                    : pos.indexOf('right') > -1
                        ? 'end'
                        : 'center';
            const classes = ['left', 'right'].includes(pos)
                ? `items-${pos === 'left' ? 'start' : 'end'} justify-center`
                : pos === 'center'
                    ? 'flex-center'
                    : `items-${align}`;

            positionClass[
                pos
            ] = `v-notifications__list v-notifications__list--${vert} ${classes}`;
            // fixed column no-wrap
        });
    },

    methods: {
        add(config, originalApi) {
            if (!config) {
                return consoleError('parameter required');
            }

            let Api;
            const notif = { textColor: 'white' };

            if (config.ignoreDefaults !== true) {
                Object.assign(notif, defaults);
            }

            if (Object(config) !== config) {
                if (notif.type) {
                    Object.assign(notif, originalApi.types[notif.type]);
                }

                config = { message: config };
            }

            Object.assign(notif, originalApi.types[config.type || notif.type], config);

            if (typeof notif.icon === 'function') {
                notif.icon = notif.icon.call(this);
            }

            if (notif.spinner === undefined) {
                notif.spinner = false;
            } else if (notif.spinner === true) {
                notif.spinner = SLoader;
            }

            notif.meta = {
                hasMedia: Boolean(notif.spinner !== false || notif.icon || notif.avatar),
            };

            if (notif.position) {
                if (positionList.includes(notif.position) === false) {
                    return consoleError('wrong position', config);
                }
            } else {
                notif.position = 'bottom';
            }

            if (notif.timeout === undefined) {
                notif.timeout = 5000;
            } else {
                const t = parseInt(notif.timeout, 10);
                if (isNaN(t) || t < 0) {
                    return consoleError('wrong timeout', config);
                }
                notif.timeout = t;
            }

            if (notif.timeout === 0) {
                notif.progress = false;
            } else if (notif.progress === true) {
                notif.meta.progressStyle = {
                    animationDuration: `${notif.timeout + 1000}ms`,
                };
            }

            const actions = (
                Array.isArray(config.actions) === true ? config.actions : []
            )
                .concat(config.ignoreDefaults !== true &&
                    Array.isArray(defaults.actions) === true
                    ? defaults.actions
                    : [])
                .concat(originalApi.types[config.type] !== undefined &&
                    Array.isArray(originalApi.types[config.type].actions) === true
                    ? originalApi.types[config.type].actions
                    : []);

            notif.closeBtn &&
                actions.push({
                    label:
                        typeof notif.closeBtn === 'string'
                            ? notif.closeBtn
                            : 'Закрыть',
                });

            notif.actions = actions.map(({
                handler,
                noDismiss,
                style,
                class: klass,
                attrs,
                ...props
            }) => ({
                staticClass: klass,
                style,
                props: { flat: true, ...props },
                attrs,
                on: {
                    click:
                        typeof handler === 'function'
                            ? () => {
                                handler();
                                noDismiss !== true && dismiss();
                            }
                            : () => {
                                dismiss();
                            },
                },
            }));

            if (notif.multiLine === undefined) {
                notif.multiLine = notif.actions.length > 1;
            }

            Object.assign(notif.meta, {
                staticClass:
                    'v-notification row items-stretch' +
                    ` v-notification--${
                        notif.multiLine === true ? 'multi-line' : 'standard'
                    }` +
                    (notif.color !== undefined ? ` v-notification-${notif.color}` : '') +
                    (notif.textColor !== undefined
                        ? ` ${notif.textColor}--text`
                        : '') +
                    (notif.classes !== undefined ? ` ${notif.classes}` : ''),
                //  relative-position border-radius-inherit
                wrapperClass:
                    'v-notification__wrapper col ' +
                    (notif.multiLine === true
                        ? 'column no-wrap justify-center'
                        : 'row items-center'),

                contentClass:
                    'v-notification__content ' +
                    (notif.multiLine === true ? '' : ' col'),
                // row items-center
                attrs: {
                    role: 'alert',
                    ...notif.attrs,
                },
            });

            if (notif.group === false) {
                notif.group = undefined;
                notif.meta.group = undefined;
            } else {
                if (notif.group === undefined || notif.group === true) {
                    // do not replace notifications with different buttons
                    notif.group = [
                        notif.message,
                        notif.caption,
                        notif.multiline,
                    ]
                        .concat(notif.actions.map(a => `${a.props.label}*${a.props.icon}`))
                        .join('|');
                }

                notif.meta.group = notif.group + '|' + notif.position;
            }

            if (notif.actions.length === 0) {
                notif.actions = undefined;
            } else {
                notif.meta.actionsClass =
                    //  row items-center
                    'v-notification__actions ' +
                    (notif.multiLine === true ? 'justify-end' : 'col-auto') +
                    (notif.meta.hasMedia === true
                        ? ' v-notification__actions--with-media'
                        : '');
            }


            const original = groups[notif.meta.group];

            // woohoo, it's a new notification
            if (original === undefined) {
                notif.meta.uid = uid++;
                notif.meta.badge = 1;

                if (
                    ['left', 'right', 'center'].indexOf(notif.position) !==
                    -1
                ) {
                    this.notifs[notif.position].splice(Math.floor(this.notifs[notif.position].length / 2), 0, notif);
                } else {
                    const action =
                        notif.position.indexOf('top') > -1
                            ? 'unshift'
                            : 'push';
                    this.notifs[notif.position][action](notif);
                }

                if (notif.group !== undefined) {
                    groups[notif.meta.group] = notif;
                }
            }
            // ok, so it's NOT a new one
            else {
                // reset timeout if any
                clearTimeout(original.meta.timer);

                if (notif.badgePosition !== undefined) {
                    if (
                        badgePositions.includes(notif.badgePosition) ===
                        false
                    ) {
                        return consoleError('wrong badgePosition', config);
                    }
                } else {
                    notif.badgePosition = `top-${
                        notif.position.indexOf('left') > -1
                            ? 'right'
                            : 'left'
                    }`;
                }

                notif.meta.uid = original.meta.uid;
                notif.meta.badge = original.meta.badge + 1;
                notif.meta.badgeStaticClass =
                    `v-notification__badge v-notification__badge--${notif.badgePosition}` +
                    (notif.badgeColor !== undefined
                        ? ` ${notif.badgeColor}`
                        : '') +
                    (notif.badgeTextColor !== undefined
                        ? ` ${notif.badgeTextColor}--text`
                        : '');

                const index = this.notifs[notif.position].indexOf(original);
                this.notifs[notif.position][index] = groups[
                    notif.meta.group
                ] = notif;
            }
            // }

            const dismiss = () => {
                this.remove(notif);
                Api = undefined;
            };

            this.$forceUpdate();

            if (notif.timeout > 0) {
                notif.meta.timer = setTimeout(() => {
                    dismiss();
                }, notif.timeout + /* show duration */ 1000);
            }

            // only non-groupable can be updated
            if (notif.group !== undefined) {

                return props => {
                    if (props !== undefined) {
                        consoleError('trying to update a grouped one which is forbidden', config);
                    } else {
                        dismiss();
                    }
                };
            }

            Api = {
                dismiss,
                config,
                notif,
            };
            console.log('🚀 ~ file: Notify.vue ~ line 340 ~ add ~ originalApi', originalApi);

            if (originalApi !== undefined) {
                Object.assign(originalApi, Api);
                return;
            }

            return props => {
                // if notification wasn't previously dismissed
                if (Api !== undefined) {
                    // if no params, then we must dismiss the notification
                    if (props === undefined) {
                        Api.dismiss();
                    }
                    // otherwise we're updating it
                    else {
                        const newNotif = Object.assign({}, Api.config, props, {
                            group: false,
                            position: notif.position,
                        });

                        this.add(newNotif, Api);
                    }
                }
            };
        },

        remove(notif) {
            clearTimeout(notif.meta.timer);

            const index = this.notifs[notif.position].indexOf(notif);
            if (index !== -1) {
                if (notif.group !== undefined) {
                    delete groups[notif.meta.group];
                }

                const el = this.$refs[`notif_${notif.meta.uid}`];

                if (el) {
                    const { width, height } = getComputedStyle(el);

                    el.style.left = `${el.offsetLeft}px`;
                    el.style.width = width;
                    el.style.height = height;
                }

                this.notifs[notif.position].splice(index, 1);

                this.$forceUpdate();

                if (typeof notif.onDismiss === 'function') {
                    notif.onDismiss();
                }
            }
        },
    },

    render(h) {
        return h('div', { staticClass: 'v-notifications' }, positionList.map(pos => h('transition-group', {
            key: pos,
            staticClass: positionClass[pos],
            tag: 'div',
            props: {
                name: `v-notification--${pos}`,
                mode: 'out-in',
            },
        }, this.notifs[pos].map(notif => {
            let msgChild;

            const meta = notif.meta;
            const msgData = {
                staticClass: 'v-notification__message col',
            };

            if (notif.html === true) {
                msgData.domProps = {
                    innerHTML: notif.caption
                        ? `<div>${notif.message}</div><div class="v-notification__caption">${notif.caption}</div>`
                        : notif.message,
                };
            } else {
                const msgNode = [notif.message];
                msgChild = notif.caption
                    ? [
                        h('div', msgNode),
                        h('div', {
                            staticClass:
                                'v-notification__caption',
                        }, [notif.caption]),
                    ]
                    : msgNode;
            }

            const mainChild = [];

            if (meta.hasMedia === true) {
                if (notif.spinner !== false) {
                    mainChild.push(h(notif.spinner, {
                        staticClass: 'v-notification__spinner',
                        props: { indeterminate: true },
                    }));
                } else if (notif.icon) {
                    mainChild.push(h(SIcon, {
                        staticClass: 'v-notification__icon',
                        attrs: { role: 'img' },
                        props: { icon: notif.icon, size: 24 },
                    }));
                }
            }

            mainChild.push(h('div', msgData, msgChild));

            const child = [
                h('div', { staticClass: meta.contentClass }, mainChild),
            ];

            notif.progress === true &&
                child.push(h('div', {
                    key: `${meta.uid}|p|${meta.badge}`,
                    staticClass: 'v-notification__progress',
                    style: meta.progressStyle,
                    class: notif.progressClass,
                }));

            notif.actions !== undefined &&
                child.push(h('div', {
                    staticClass: meta.actionsClass,
                }, notif.actions.map(action => {
                  const children = []
                  const {iconName, text, SButtonProps} = action.props
                  const props = {...SButtonProps}

                  if (text) children.push(text)
                  else props.equal = true

                  if (iconName) children.push(
                    h(SIcon, {
                      slot: 'default',
                      props: {
                        size: 24,
                        icon: iconName,
                      }
                    })
                  )

                  return h(
                    SButton, 
                    { 
                      ...action,
                      props,
                    }, 
                    !(text && iconName) ? children : [
                      h('span', {
                        style: 'display: inline-flex; align-items: center'
                      },
                      children)
                    ]
                  )
                })));

            meta.badge > 1 &&
                child.push(h('div', {
                    key: `${meta.uid}|${meta.badge}`,
                    staticClass: meta.badgeStaticClass,
                    style: notif.badgeStyle,
                    class: notif.badgeClass,
                }, [meta.badge]));

            return h('div', {
                ref: `notif_${meta.uid}`,
                key: meta.uid,
                staticClass: meta.staticClass,
                attrs: meta.attrs,
            }, [
                h('div', { staticClass: meta.wrapperClass }, child),
            ]);
        }))));
    },
};

