import { defineStore } from 'pinia';

import {
  useCitiesStore
} from '@/store/cities';

interface Specs {
  activeBookings: number;
  completedBookings: number;
  notifications: number;
  interests: number;
  interestsMine: number;
  interestsManager: number;
  favourites: number;
  minPropertyPrice: number;
  phone: string;
  offers: number;
}

interface State {
  specs?: Specs;
  isLoadingGetSpecs: boolean;
}

export const useDashboardSpecsStore = defineStore('dashboardSpecs', {
  state: (): State => ({
    specs: undefined,
    isLoadingGetSpecs: true
  }),

  getters: {
    hasInterests (state): boolean {
      const {
        interests
      } = state.specs ?? {};

      return Boolean(interests);
    },

    hasBookings (state): boolean {
      const {
        activeBookings,
        completedBookings
      } = state.specs ?? {};

      return Boolean(activeBookings || completedBookings);
    },

    hasCompletedBookings (state): boolean {
      const {
        completedBookings
      } = state.specs ?? {};

      return Boolean(completedBookings);
    },

    hasActiveBookings (state): boolean {
      const {
        activeBookings
      } = state.specs ?? {};

      return Boolean(activeBookings);
    }
  },

  actions: {
    async getSpecs (): Promise<void> {
      const cities = useCitiesStore();

      const {
        value: city
      } = cities.currentCity ?? {};

      if (!city) {
        return;
      }

      if (this.$auth.$state.loggedIn) {
        this.isLoadingGetSpecs = true;
        try {
          const {
            data
          } = await this.$axios.get<Specs>('api/dashboard/specs', {
            params: {
              city
            }
          });

          this.specs = data;
        } catch (error) {
          this.$sentry.captureException(error);
          console.error('🚀 ~ file: specs.ts ~ getSpecs ~ e', error);
        } finally {
          this.isLoadingGetSpecs = false;
        }
      }
    }
  }
});
